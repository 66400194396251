import React from "react";

function NewproductCard() {
  return (
  <div className="overflow-hidden mx-auto transition-shadow duration-300 shadow-lg bg-white rounded w-4/5 relative">
    <div  className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-200 relative" >
      <img
        class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
        src="https://images.pexels.com/photos/3563621/pexels-photo-3563621.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
        alt=""
      />
      <div class="relative flex flex-col justify-between p-4 leading-normal">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          Frijoles Machacados
        </h5>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
          Descripcion del productos
        </p>
      </div>
    </div>
    <div class="absolute top-0 -right-[6rem] transform translate-y-[12.5rem] bg-red-500 text-white font-bold py-2 px-24 -rotate-45 z-1">
      Lanzamiento Próximo
    </div>
</div>

  );
}
export { NewproductCard };
