import React, { useEffect, useState } from "react";
import axios from "axios";  
import parse from "html-react-parser";
import { NavLink } from "react-router-dom";
import { ErrorScreen } from "./error-screen";
function ArticuloBlog(){
    const [error, setError] = useState(false);
    const [img, setImg] = useState("");
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [date, setDate] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/articlesFull')
            .then((res) => {
                console.log(res.data.articlesFull[0].article);
                setText(res.data.articlesFull[0].article.content);
                setTitle(res.data.articlesFull[0].article.title);
                setImg(res.data.articlesFull[0].article.img);
                setDate(res.data.articlesFull[0].article.date);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                if (isLoading) {
                    setIsLoading(false);
                }
            });
    }, []);
    return(
        <>
        {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                   error ? <ErrorScreen /> :
                <div>
                    <div className="container mx-auto px-6 py-16 text-center">
                        <div className="mx-auto max-w-lg">
                            <h1 className="text-2xl font-semibold text-gray-800 dark:text-white ">{title}</h1>
                        </div>

                        <div className="mt-10 flex justify-center">
                                <img className="h-96 w-full rounded-xl object-cover lg:w-4/5" src={img} />
                        </div>
                        <span className="text-right text-2xl block my-5">{date}</span>
                        <p className="mt-6 text-base text-justify">{parse(text)}</p>
                    </div>

                    <div className="flex space-x-2 justify-center ">
                        <NavLink to='/blog'><button type="button" className="my-10 inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 transition duration-150">Regresar a Blog</button>
                        </NavLink>
                    </div>
            </div>
            }
</>

            );

}
export {ArticuloBlog};