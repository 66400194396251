import React from "react";
import './ImgCarouselStyles.css'

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
function ImgCarousel(props) {
   
    return (
        
        <Carousel className='carousel' showArrows={false} autoPlay={true} infiniteLoop={true} interval={7000}>           
                { Array.isArray(props.img) ? (
                        props.img.map((images, index) => (
                            <div key={index}>
                                 <img src={images} alt={`Imagen ${index + 1} de carousel`} />
                                 <div className="absolute w-1/2 top-1/4 left-10 p-12 text-white justify-start font-bold text-5xl">
                                   El toque secreto detrás de cada gran receta: nuestras especias.
                                </div>
                            </div>
                    ))
                ) : (
                    <div>
                            <img src={props.img} alt={`Imagen unica de carousel`} />
                    </div>
                )}
        </Carousel>


    );
}

export {ImgCarousel}
