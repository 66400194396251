import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CarouselComp } from '../components/carousel/carousel';
import axios from "axios";
import parse from "html-react-parser";
import { ErrorScreen } from "./error-screen";
/*Importacion de imagenes de prueba */
import img1 from '../assets/paisaje.jpg'
import img2 from '../assets/equipo2.jpg'
import img3 from '../assets/productos3.jpg'
import img4 from '../assets/equipo1.jpg'

function ZontleAbout(){
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [section1, setSection1] = useState({ isActive: true, img: "", title: "", text: "" });
  const [section2, setSection2] = useState({ isActive: false, text: "" });
  const [section3, setSection3] = useState({ isActive: false, img: "", title: "", text: "" });
  const [section4, setSection4] = useState({ isActive: false, text: "" ,imagenes: [""] });
  const [section5, setSection5] = useState({ isActive: false, storeList: [] });
  const [section6, setSection6] = useState({ isActive: false, title: "", btnText: "", btnUrl: "" });
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/aboutUs')
        .then((res) => {
            console.log(res);
            const base = res.data.aboutUs;
            const sec1 = base.company;
            const sec2 = base.mission;
            const sec3 = base.vision;
            const sec4 = base.region;
            const sec5 = base.stores;
            setSection1({ isActive: sec1.isActive, img: sec1.img, title: sec1.title , text: sec1.text });
            setSection2({ isActive: sec2.isActive, text: sec2.text,img: sec1.img });
            setSection3({ isActive: sec3.isActive, img: sec3.img, text: sec3.text });
            setSection4({ isActive: sec4.isActive, text: sec4.text,imagenes: sec4.gallery });
            setSection5({ isActive: sec5.isActive, storeList: sec5 });

            setIsLoading(false);
            console.log(sec1);
          })
        .catch(() => {
            setError(true);
        })
        .finally(() => {
            if (isLoading) {
                setIsLoading(false);
            }
        });
    }, []);
    return(
    <>
     {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                error ? <ErrorScreen /> :
            <div> 
                {/* section 1 imagen */}
                <div className={(section1.isActive ? "" : "hidden") + " relative h-[38rem]"}>
                    <div className="relative">
                        <img className="w-full bg-center bg-cover h-[38rem]" src={section1.img} alt="imagen empresa" />
                        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-900/40">
                            <div className="text-center">
                                <h1 className="text-3xl font-semibold text-white lg:text-4xl">{section1.title}</h1>
                            </div>
                        </div>
                    </div>

                    {/* descripcion */}
                    <div className="overflow-hidden w-full bg-center bg-gray-600 bg-cover lg:h-[10rem]">
                        <p className="text-justify lg:text-base md:text-sm text-xs text-white p-4">
                            {section1.text}
                        </p>
                    </div>
                </div>

                {/* section 3 vision */}
                <div className="mt-[10rem]">
                    {/* Mision */}
                    <div className={(section2.isActive ? "" : "hidden ")+"overflow-hidden bg-white dark:bg-gray-900 lg:flex lg:w-full lg:shadow-md"}>
                        <div className="lg:w-1/2">
                                <img className="h-64 w-full bg-cover object-cover lg:h-ful" src={section2.img} alt="imagen Vision"/> {/* iamgagen */}
                        </div>
                        <div className="max-w-xl px-6 py-10 lg:w-1/2 lg:max-w-5xl">
                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">Misión</h2>
                            <p className="mt-4 text-base text-gray-500 dark:text-gray-300">{section2.text}</p>
                        </div>                    
                    </div> 
                    {/* Vision */}
                    <div className={(section2.isActive ? "" : "hidden ")+"overflow-hidden bg-[#EB743D] lg:flex lg:w-full  lg:shadow-md"}>
                        <div className="max-w-xl px-6 py-12 lg:w-1/2 lg:max-w-5xl justify-center">
                            <h2 className="text-2xl font-semibold text-white md:text-3xl">Visión</h2>
                            <p className="mt-4 text-base text-white">{section3.text}</p>
                        </div>
                        <div className="lg:w-1/2">
                            <img className="h-64 w-full bg-cover object-cover lg:h-ful" src={section3.img} alt="imagen Vision"/> {/* iamgagen */}

                        </div>
                    </div>
                </div> 
                {/* section  4 Nuestra region */}
                <div className={(section4.isActive ? "" : "hidden ")+"container mx-auto px-6 py-8 lg:py-16"}>
                    <h3 className="text-xl font-medium text-orange-500 underspace md:text-2xl lg:text-3xl">Nuestra Region</h3>
                    <p className="mt-4 text-base text-gray-900 dark:text-gray-900">{section4.text}</p>
                    
                    <div className="mt-10 grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 ">
                         { section4.imagenes.map((gallery, index) => (
                            <div className={`transition-all duration-500 hover:scale-105 ${index % 2 === 0 ? 'lg:col-span-2' : ''}`} key={index}>
                            <img className={`h-80 w-full rounded-lg ${index % 2 === 0 ? 'bg-cover' : 'object-cover'} shadow-md shadow-gray-200 xl:h-96`}
                                src={gallery} alt={`Imagen region ${index + 1}`}/>
                            </div>
                        ))}
                    </div>
                </div>
                {/* section 5 Distribucion esta es la misma seccion que el anteriro solo lo agregas*/}
                <div className={(section5.isActive ? "" : "hidden") + "bg-comp rounded-md m-8 pb-5 bg-[#49AA41]"}>
                            <h2 className="font-bold  text-2xl text-center text-white py-5">Puedes encontrar nuestros productos en:</h2>
                            <CarouselComp store={section5.storeList} />

                        </div>
                {/* conoce productos */}
                <div>
                    <div className="flex flex-col items-center justify-center w-full max-w-sm mx-auto">
                        <div className="w-screen h-40 bg-gray-300 bg-center bg-imgpromo bg-cover shadow-md" ></div>

                        <div className="lg:w-[50rem] md:w-[40rem] sm:w-[35rem] w-screen -mt-[5.5rem] overflow-hidden bg-[#eb743d] rounded-lg shadow-lg">
                            <h3 className="pl-8 pt-8 font-bold tracking-wide text-white uppercase">Conoce nuestros productos</h3>

                            <div className="flex items-center justify-between px-9 pb-6 bg-[#eb743d]">
                                <span className="font-bold text-base text-white dark:text-white">Nuestro compromiso de calidad en cada producto se ve presente dia con dia en cada uno de nuestros procesos</span>
                                <NavLink to="/productos">
                                    <button className="px-2 py-1 text-xs font-semibold text-white uppercase transition-colors duration-300 transform bg-gray-800 rounded hover:bg-gray-700 dark:hover:bg-gray-600 focus:bg-gray-700 dark:focus:bg-gray-600 focus:outline-none">IR A PRODUCTOS</button>
                                </NavLink>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* seccion 6 de Historia */}
                <div>
                    <div className="w-full bg-center bg-historia bg-cover h-[38rem] -mt-16" >
                        
                    </div>
                </div>
                
            </div>
}
    </>
    );

}
export {ZontleAbout};