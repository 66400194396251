import React, { useEffect } from "react";

function ErrorScreen() {
    function retry() {
        window.location.reload();
    }
    useEffect(() => window.scrollTo(0, 0), []);
    return (
        <div className="flex flex-col justify-center items-center h-screen p-40 text-tgray">
            <h1 className="text-4xl lg:text-9xl text-center mb-10">:(</h1>
            <h1 className="text-2xl lg:text-7xl text-center">Parece que hay un problema con tu conexión a internet.</h1>
            <button onClick={retry} type="button" className="mt-10 inline-block px-8 py-6 bg-gray-800 text-white font-medium text-md leading-tight uppercase rounded shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out">Reintentar</button>
        </div>
    )
}

export { ErrorScreen }