import React, { useState } from "react";
import axios from "axios";
function RegisterForm() {
  return (
      <div className="lg:w-3/4 mx-auto md:w-4/5">
        <div className=" bg-[#eb743d] rounded-t-lg ">
          <h1 className="p-4 font-bold text-letf text-white">Contactanos</h1>
        </div>
        <form className="bg-gray-50 border border-[#eb743d] border-2 p-4 rounded-b-lg text-base ">
          <div class="-mx-2 md:items-center md:flex">
            <div class="flex-1 px-2 ">
              <input
                type="text"
                placeholder="Nombre "
                className="form-control block w-full px-3 py-1.5 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              />
            </div>

            <div class="flex-1 px-2 mt-4 md:mt-0">
              <input
                type="text"
                placeholder="Apellidos"
                class="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
          </div>
          <div class="-mx-2 md:items-center md:flex">
            <div class="flex-1 px-2">
              <input
                type="text"
                placeholder="País "
                class="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>

            <div class="flex-1 px-2 mt-4 md:mt-0">
              <input
                type="text"
                placeholder="Estado"
                class="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
          </div>

          <div class="mt-4">
            <input
              type="email"
              placeholder="Correo electrónico"
              class="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div class="mt-4">
            <input
              type="tel"
              placeholder="Teléfono"
              class="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div class="w-full mt-4">
            <textarea
              class="block w-full h-32 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg md:h-32 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              placeholder="Mensaje"
            ></textarea>
          </div>
          <div className="flex justify-end">
            <button class=" w-1/2 px-4 py-2 mt-4 text-sm font-medium tracking-wide text-white transition-colors duration-300 transform bg-[#eb743d] rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-[#eb743d] focus:ring-opacity-50">
              Enviar
            </button>
          </div>
        </form>
      </div>
   
  );
}
export { RegisterForm };
