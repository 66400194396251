import React from 'react';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import { Navbar } from './components/navbar'
import { Footer } from './components/footer/footer'
import { Home } from './screens/home'
import { Products } from './screens/products'
import { ProductsGeneral } from './screens/products-general'
import { BlogGeneral } from './screens/blog-general'
import { RecetaGeneral } from './screens/receta-general'
import { ArticuloBlog } from './screens/aticulo-blog'
import { ZontleAbout } from './screens/zontle-about'
import { Contatc } from './screens/contatc'
import { NotFound } from './screens/404-notfound';



function App() {
  return (
    <div className="App">
     <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/productos" element={<Products />} />
          <Route path="/productos/:id" element={<ProductsGeneral />} />
          <Route path="/blog" element={<BlogGeneral />} />
          <Route path="/recipes/:id" element={<RecetaGeneral />} />
          <Route path="/articulo/:id" element={<ArticuloBlog />} />
          <Route path="/zontle" element={<ZontleAbout />} />
          <Route path="/contacto" element={<Contatc />} />
          <Route path="/home" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer/>  
    </BrowserRouter>
    </div>
  );
}

export default App;
