import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Player } from '@lottiefiles/react-lottie-player';


function NotFound() {
    useEffect(() => window.scrollTo(0, 0), []);
    return (


    <div className="container flex items-center min-h-screen px-6 py-12 mx-auto">
        <div className="flex flex-col items-center max-w-sm mx-auto text-center">
        <Player
        src='https://lottie.host/2c005930-0ed4-4424-9de1-61009908fc19/wnRguL6txj.json'
        className="player"
      />

            <h1 className="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">Pagina no encontrada</h1>
            <p className="mt-4 text-gray-500 dark:text-gray-400">La página que estás buscando no existe. Aquí hay algunos enlaces útiles:</p>

            <div className="flex items-center justify-center  w-full mt-6 sm:w-auto">
            <NavLink to="/">

                <button className="w-full p-4 py-2 text-lg text-gray-800 transition-colors duration-200 bg-white border rounded-lg sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-[#eb743d] hover:text-white ">
                   <span>Regresar a Inicio</span>
                </button>  
            </NavLink> 
            
            </div>
        </div>
    </div>
    
    )
}

export { NotFound }