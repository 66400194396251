import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { ErrorScreen } from "./error-screen";
import { ProductCard } from "../components/product-card";
import { NewproductCard } from "../components/newproduct-card";
/*Icono utilizados */
import { FaWhatsapp } from 'react-icons/fa';
/*css */
import "../components/component-css/loader.css";
import '../components/component-css/backgroungs-general.css'
function Products(){
    const [error, setError] = useState(false);
    const [prods, setProds] = useState([]);
    const [contact, setContac] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/products')
            .then((res) => {
                const sec1 = res.data.products;
                const sec2 = res.data.ShopInfo;
                setProds(sec1);
                setContac(sec2);
                setIsLoading(false);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
        , []);
    return (
        <>
        {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                error ? <ErrorScreen /> :
            <div className="bg-gray-100">
                 {/* Seccion 1  */}

                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-8">
                    <div className="text-center  p-4">
                        <h3 className="font-semibold tracking-wider text-orange-500 uppercase">Productos</h3> 
                        <p className="text-sm font-bold text-gray-800 ">Creados con pasión, nuestros productos te brindarán una experiencia excepcional</p>
                    </div> 
                    <div className="grid gap-5 lg:grid-cols-3  sm:max-w-sm sm:mx-auto lg:max-w-full ">
                    {/*Productos  */}
                    {  
                       prods.map((item, i) => {
                            return  <NavLink to={"/productos/"+ item.product.id }> <ProductCard title={item.product.name} productImg={item.product.img}/></NavLink>
                        })
                    }                
                    </div>
                </div>
                {/* Seccion 2 con Whastapp */}
                <div className="relative flex">
                    <div className="h-60 bg-[#5D5D61]  lg:w-4/5 md:w-5/6 sm:w-screen text-center py-16 inline-block">
                        <div className="flex flex-col justify-center items-center h-full">
                        
                            <h1 className="font-bold text-white">{contact.whatsapp.title}</h1>
                            <a  href={contact.whatsapp.shopUrl} className="cursor-pointer bg-green-500 hover:bg-green-600 text-white px-4 py-2 flex items-center space-x-2 rounded">
                            <FaWhatsapp />
                            <span>WhatsApp</span>
                            </a>
                            <p className="text-sm font-semibold text-white">{contact.whatsapp.text}</p>
                        </div>
                    </div>
                    <div className="h-60 imagen-bag md:w-2/5"></div>               
                </div>
                {/*Seccion 3 de productos nuevos */}
                <div>
                    <div className="text-center  p-4">
                        <h3 className="font-semibold tracking-wider text-orange-500 uppercase">Nuevos Productos Proximente</h3> 
                        <p className="text-sm font-bold text-gray-800 ">¡Estamos trabajando en traerte emocionantes productos! Mantente al tanto de las novedades.</p>
                    </div> 
                    <div className="grid gap-5 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full p-4">
                            <NewproductCard/>               
                            <NewproductCard/>             
                    </div>
                </div>
            </div>
        }
        </>

    );

}
export {Products};