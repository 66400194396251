import React from "react";
function ArticleCard(props) {
  return (
    <div className="overflow-hidden transition-shadow duration-300 shadow-lg bg-white rounded  hover:bg-gray-200">
      <a href="/" aria-label="Article">
        <img src={props.img} className="object-cover w-full h-64 rounded" alt="Imagen de articulo"/>
      </a>
      <div className="py-3">
        <p className="mb-2 p-2 text-xs font-semibold text-gray-600 uppercase">{props.date}</p>
        <a
          href="/"
          aria-label="Article"
          className="inline-block mb-3 p-2 text-black transition-colors duration-200 hover:text-deep-purple-accent-600"
        >
          <p className="text-2xl font-bold leading-5">{props.title}</p>
        </a>
        <p className="mb-4 p-2 text-sm text-gray-700">{props.text}</p>
        <div className="flex space-x-4"></div>
      </div>
    </div>
  );
}
export { ArticleCard };
