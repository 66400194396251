import React from "react";
import { NavLink } from "react-router-dom";

function Navbar(){
    return (
        <nav className="sticky inset-0 z-10 block h-max w-full max-w-full rounded-none bg-[#eb743d] py-5 px-4 text-white  lg:px-8 lg:py-4">
           <div class="flex items-center text-white-900">
                
                <ul className="ml-auto mr-8 hidden items-center gap-6 lg:flex">
                <li className="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
                    <NavLink to='/'><a className="flex items-center" href="#"> INICIO </a>
                    </NavLink>
                </li>
                <li className="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
                <NavLink to='/productos'>
                    <a className="flex items-center" href="../screens/products.js"> PRODUCTOS </a></NavLink>   
                </li>
                <li className="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
                <NavLink to='/blog'>
                    <a className="flex items-center" href="#">BLOG</a></NavLink>   
                </li>
                <li className="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
                <NavLink to='/zontle'>
                    <a className="flex items-center" href="#"> ZONTLE </a></NavLink>   
                </li>
                <li className="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
                <NavLink to='/contacto'>
                    <a className="flex items-center" href="#"> CONTACTO </a> </NavLink>   
                </li>
                </ul>
                <button className="middle none text-blue-gray-500 relative ml-auto h-6 max-h-[40px] w-6 max-w-[40px] rounded-lg text-center font-sans text-xs font-medium uppercase transition-all hover:bg-transparent focus:bg-transparent active:bg-transparent disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none lg:hidden" data-collapse-target="sticky-navar">
                <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </span>
                </button>
            </div>
            <div className="text-blue-gay-900 block h-0 w-full basis-full overflow-hidden transition-all duration-300 ease-in lg:hidden" data-collapse="sticky-navar">
            <ul className="ml-auto mr-8 hidden items-center gap-6 lg:flex">
                <li className="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
                    <NavLink to='/'><a className="flex items-center" href="#"> INICIO </a>
                    </NavLink>
                </li>
                <li className="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
                <NavLink to='/productos'>
                    <a className="flex items-center" href="../screens/products.js"> PRODUCTOS </a></NavLink>   
                </li>
                <li className="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
                <NavLink to='/blog'>
                    <a className="flex items-center" href="#">BLOG</a></NavLink>   
                </li>
                <li className="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
                <NavLink to='/zontle'>
                    <a className="flex items-center" href="#"> ZONTLE </a></NavLink>   
                </li>
                <li className="block p-1 font-sans text-sm font-normal leading-normal text-inherit antialiased">
                <NavLink to='/contacto'>
                    <a className="flex items-center" href="#"> CONTACTO </a> </NavLink>   
                </li>
                </ul>
    </div> 
        </nav>
    );

}
export {Navbar};
