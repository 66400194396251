import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { ErrorScreen } from "./error-screen";
import { FaWhatsapp } from 'react-icons/fa';
 function ProductsGeneral(){
   const [error, setError] = useState(false);
   const [img, setImg] = useState("");
   const [name, setTitle] = useState("");
   const [prices, setPrice] = useState("");
   const [description, setText] = useState("");
   const [presentations, setPresentations] = useState([]);
   const [Informacion, setInform] = useState([]);
   const [contact, setContac] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/products')
            .then((res) => {
               console.log(res.data.products[0].product);
               setImg(res.data.products[0].product.img);
               setTitle(res.data.products[0].product.name);
               setText(res.data.products[0].product.description);
               setPrice(res.data.products[0].product.wholesalePrice);
               setPresentations(res.data.products[0].product.presentations);
               setInform(res.data.products[0].product.nutritionFacts);
               setContac(res.data.ShopInfo);

            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
        , []);

    return (
      <>
     {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                error ? <ErrorScreen /> :
            <div>
               {/* La tarejta del producto */} 
               <div className="items-start justify-center md:flex md:px-6 ">
                  <div className="w-28 flex flex-col items-center justify-center lg:py-20 py-2 lg:ml-0 ml-40">
                     <img className="w-full" alt="" src={img} />  {/* IMAGEN */} 
                     <div className="text-center">
                        <a  href={contact.whatsapp.shopUrl} className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 flex items-center space-x-2 rounded ml-[-2rem]">
                           <FaWhatsapp />
                           <span>WhatsApp</span>
                        </a>
                     </div>
                     </div>

                      {/* Informacion PRODUCTO */} 
                     <div className="p-4 md:ml-6 md:mt-0 md:w-1/2 lg:ml-8 text-base">
                        <div className="border-b border-gray-200 p-4">
                           <h1 className="max-w-6xl py-10 mx-auto mt-2 text-2xl font-semibold text-gray-800 capitalize ">{name}</h1>
                           <p className="text-gray-600 dark:text-gray-300">{description}</p>
                        </div>
                        <div className="border-b border-gray-200 p-4" >
                           <p className="text-gray-600 mb-1">Precio al Mayoreo : $ {prices} por unidad</p>
                           <p className="text-gray-600 mb-1">Cantidad Mínima de Compra: 20 unidades</p>  
                        </div>
                        <div className="flex items-center justify-between border-b border-gray-200 p-4">
                           <p className="leading-4 text-gray-800 dark:text-gray-300">Presentaciones</p>
                           <div className="flex gap-2">
                                 {presentations.map((presentation, index) => (
                                       <div key={index} className="center relative inline-block select-none whitespace-nowrap rounded-lg bg-blue-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                                       <div className="mt-px">{presentation}</div>
                                       </div>
                                  ))}
                                 </div>
                        </div>     
                        
                        <div> {/* Informacion tabla */} 
                           <p className="mt-7 text-base leading-normal text-gray-600 dark:text-gray-300 lg:leading-tight xl:pr-48">Informacion nutrimental</p>
                              <div className="bg-white pb-4 px-4 rounded-md w-full">
                                    <table className="table-auto border-collapse w-full">
                                       <tbody className="text-sm font-normal text-gray-700">
                                       {Informacion.isActive === "true" &&
                                             Informacion.details.map((detail, index) => (
                                             <tr key={index} className={index % 2 === 0 ? "hover:bg-gray-100 border-b border-gray-200 py-10" : "hover:bg-gray-100 border-gray-200"}>
                                                <td className="px-4 py-4">{detail[0]}</td>
                                                <td className="px-4 py-4">{detail[1]}</td>
                                             </tr>
                                          ))}
                                        
                                       </tbody>
                                    </table>
                              </div>
                        </div>
                        

                     </div>
                     
                  </div>
                  <div className="flex space-x-2 justify-center ">
                  <NavLink to="/productos"><button type="button" className="my-10 inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 transition duration-150">Regresar a Productos</button></NavLink>
                  </div>


            </div>
 }
      </>
    );

 }
 export {ProductsGeneral};