import React, { useEffect, useState } from "react";
import { ImgCarousel } from '../components/carousel/imgcarousel';
import { ArticleCard } from "../components/article-card";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { ErrorScreen } from "./error-screen";
/* estilos en css */
import "../components/component-css/backgroungs-general.css"
import "../components/component-css/loader.css";
function Home() {

  const [error, setError] = useState(false);
  const [section1, setSection1] = useState({ isActive: true });
  const [section2, setSection2] = useState({ isActive: false, title: "", text: "", sideImage: "./img/cargando.png" });
  const [section3, setSection3] = useState({ isActive: false, title: "", text: "",date:" ",articles: [""] });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
      window.scrollTo(0, 0);
      axios.get('https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/home')
          .then((res) => {
              const base = res.data.home;
              const sec1 = base.carrusel;
              const sec2 = base.aboutUs;
              const sec3 = base.articlesRecent;
              setSection1({ isActive: sec1.isActive, img: sec1.images});
              setSection2({ isActive: sec2.isActive, title: sec2.cardTitle, text: sec2.cardText, sideImage: sec2.imageUrl });
              setSection3({ isActive: sec3.isActive, title: sec3.sectionTitle, text: sec3.description, date: sec3.date, articles: sec3.articleList });
              setIsLoading(false);
              console.log(sec1);
          }).catch(() => {
              setError(true);
          }).finally(() => {
              if (isLoading) {
                  setIsLoading(false);
              }
          });
  }, []);
  return (
    <>
      {/*Loader */}
    {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
    error ? <ErrorScreen /> :
      <div >
        {/*secion 1 de el carousel */}     
        <div className={(section1.isActive ? "" : "hidden ")+"carousel"}>
          <ImgCarousel img={section1.img} />
        </div>
       
        {/*secion 2 de el Nosotros */}
          <div className={(section2.isActive ? "" : "hidden ")+"about bg-inicio"}>
              <div className="text-center">
                  <h1 className="text-2xl p-4 font-bold text-orange-500 lg:text-3xl dark:text-white">Somos orgullosamente Mexicanos</h1>
              </div> 
              
              <div className="grid lg:grid-cols-2 sm:max-w-xl md:max-w-full lg:max-w-screen-xl p-4">
              <div className="flex flex-col mx-auto lg:ml-[7rem] ml-0 ">
                    <div className="z-0 lg:ml-8 ml-0">
                        <img class={(section2.isActive ? "" : "hidden ")+ "h-[20rem] w-auto rounded-lg object-cover shadow-md md:h-[32rem]  lg:h-[30rem] lg:w-[26rem] md:rounded-lg"} src={section2.sideImage} alt="empresa foto" />
                    </div>
                    <div className="lg:w-[35rem] lg:h-[18rem] lg:-mt-[15rem] bg-orange-400 rounded-lg shadow-lg">
                    </div>
              </div>


                  <div className={(section2.isActive ? "" : "hidden ")+"flex flex-col  mx-auto lg:mt-[4rem] mt-4 " }>
                      <div className="bg-gray-200 rounded lg:w-4/5 p-4 drop-shadow-lg">
                          <h2 className="text-lg font-semibold p-4">{section2.title}</h2>
                          <p className=" text-base p-4">{section2.text}</p>
                          <div className="p-2 mt-4 w-32 text-center text-lg text-white transition-colors duration-200 bg-[#eb743d] border rounded-lg  hover:bg-[#eb863d] hover:text-white">
                            <NavLink to="/zontle"> <button type="button">Saber más</button></NavLink>
                          </div>
                      </div>
                  </div>
              </div>
           </div>
       
        {/*secion 3 de el Articulos recientes*/}
          <div className={(section3.isActive ? "" : "hidden ")+"bg-gray-200" }>          
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">

                  <div className="text-center">
                      <h3 className="font-semibold tracking-wider text-orange-500 uppercase">Nuestros Articulos</h3> 
                      <h1 className="text-2xl p-4 font-bold text-gray-800 lg:text-3xl dark:text-white">Articulos Recientes</h1>
                  </div> 

                  <div className="grid gap-5 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
                  {
                     section3.articles.map((item, i) => {
                         if (section3.articles != "") {
                                 return (<div key={item.article.title + i} className="m-4">
                                               <NavLink to={"/articulo/" + item.article.id}>
                                               <ArticleCard key={item.article.title + i} date={item.article.date} text={item.article.description} title={item.article.title} img={item.article.img} /></NavLink>
                                          </div>)
                            }
                      })
                  }                   
                  </div>
            </div>
          </div>    
    
      </div>
    }
    </>
  );
}
export { Home };
