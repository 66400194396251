import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { RecipesCard } from "../components/recipes-card";
import { ArticleCard } from "../components/article-card";
import { ErrorScreen } from "./error-screen";
/*css */
import "../components/component-css/loader.css";
function BlogGeneral (){
      const [error, setError] = useState(false);
      const [recipes, setRecipes] = useState([]);
      const [articles, setArticles] = useState([]);
      const [isLoading, setIsLoading] = useState(true);
      const [visibleRecipes, setVisibleRecipes] = useState(3);
      const [visibleArticles, setVisibleArticles] = useState(3);
      useEffect(() => {
          window.scrollTo(0, 0);
          Promise.all([
          axios.get('https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/recipes'),
          axios.get('https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/blog') 
        ])
              .then((res) => {
                setRecipes(res[0].data.recipes);
                setArticles(res[1].data.blog);
                setIsLoading(false);
              })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                if (isLoading) {
                    setIsLoading(false);
                }
            });
    }, []);

    const recipesShowMore = () => {
      setVisibleRecipes((prevVisibleRecipes) => prevVisibleRecipes + 3);
    };
    const articuleShowMore = () => {
      setVisibleArticles((preVisibleArticles) => preVisibleArticles + 3);
    };
    
    return(
      <>
      {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                error ? <ErrorScreen /> :
          <div className="bg-[#F5F5F5]">
            {/*secion 1 Recetas*/}
            <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
                  <div className="text-center p-4">
                      <h3 className="font-semibold tracking-wider text-orange-500 uppercase">RECETAS</h3> 
                      <p className="text-sm font-bold text-gray-800 ">"Sabores irresistibles en cada plato"</p>
                  </div> 
                {recipes.length === 0 ? <div className="text-2xl my-auto border-0 text-center">No hay recetas para mostrar.</div> : null}

                <div className="grid gap-5 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
                    { recipes.slice(0, visibleRecipes).map((item) => {
                      return (
                        <div key={item.recipe.title} className="mx-auto">
                          <NavLink to={"/recipes/" + item.recipe.id}>
                            <RecipesCard title={item.recipe.title} img={item.recipe.img} text={item.recipe.description} />
                          </NavLink>
                        </div>
                      );
                    })}
                </div>

                {visibleRecipes < recipes.length && (
                      <div className="p-2 mt-4 w-32 text-center text-lg text-white transition-colors duration-200 bg-[#eb743d] border rounded-lg hover:bg-[#eb863d] hover:text-white">
                        <button type="button" onClick={recipesShowMore}>
                          Mirar más
                        </button>
                      </div>
                    )}
                {visibleRecipes > 3 && (
                  <div className="p-2 mt-4 w-32 text-center text-lg text-white transition-colors duration-200 bg-[#eb743d] border rounded-lg hover:bg-[#eb863d] hover:text-white">
                    <button type="button" onClick={() => setVisibleRecipes(3)}>
                      Mirar menos
                    </button>
                  </div>
                )}

            </div>
            {/*secion de el Articulos recientes*/}
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="text-center p-4">
                    <h3 className="font-semibold tracking-wider text-orange-500 uppercase">Articulos</h3> 
                    <p className="text-sm font-bold text-gray-800 ">"La sección de artículos de zontle es tu destino para estar al tanto de las últimas novedades"</p>
                </div>
                {articles.length === 0 ? <div className="text-2xl my-auto border-0 text-center">No hay artículos para mostrar.</div> : null}
                <div className="grid gap-5 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
                 
                  { articles.slice(0, visibleArticles).map((item) => {
                      return (
                        <div key={item.article.id} >
                        <NavLink to={"/articulo/" + item.article.id}>
                          <ArticleCard title={item.article.title} img={item.article.img} date={item.article.date} text={item.article.description} />
                          </NavLink>
                      </div>
                      );
                    })}
                    
                </div>
                {visibleArticles < recipes.length && (
                      <div className="p-2 mt-4 w-32 text-center text-lg text-white transition-colors duration-200 bg-[#eb743d] border rounded-lg hover:bg-[#eb863d] hover:text-white">
                        <button type="button" onClick={articuleShowMore}>
                          Mirar más
                        </button>
                      </div>
                    )}
                {visibleArticles > 3 && (
                  <div className="p-2 mt-4 w-32 text-center text-lg text-white transition-colors duration-200 bg-[#eb743d] border rounded-lg hover:bg-[#eb863d] hover:text-white">
                    <button type="button" onClick={() => setVisibleArticles(3)}>
                      Mirar menos
                    </button>
                  </div>
                )}
            </div>
            

          </div>
      }
      </>
    );
 


}
export {BlogGeneral};