import React from "react";

/*Importacion de imagenes de prueba */
import img1 from '../assets/logo-footer.png'

function ContactCard(props){
    return(
    <div className="mx-auto w-full overflow-hidden rounded-lg px-8 py-6 bg-white shadow-2xl shadow-gray-300 lg:max-w-xl">
                             
        <div className="w-[8rem] mx-auto">
            <img src={img1} alt="Imagen" className="w-full mx-auto" />
        </div>
        <h1 className="text-sm  font-bold  text-center text-gray-700">Síguenos en las redes</h1>
        <div className="md:mt-4 mx-auto">
            <div className=" flex justify-center">
                <a className={(props.contInfo.facebook.isActive ? "" : "hidden ") +"mx-1.5 transform text-gray-400 transition-colors duration-300 hover:text-blue-500 dark:hover:text-blue-400"} href={props.contInfo.facebook.link}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                    <path d="M26.9999 4.18726C14.4285 4.18726 4.20068 14.421 4.20068 27C4.20068 39.5794 14.4285 49.8128 26.9999 49.8128C39.5717 49.8128 49.7999 39.5794 49.7999 27C49.7995 14.421 39.5713 4.18726 26.9999 4.18726ZM26.9999 47.5628C15.6683 47.5628 6.44939 38.3385 6.44939 27C6.44939 15.6619 15.6683 6.43726 26.9999 6.43726C38.3315 6.43726 47.5512 15.6619 47.5512 27C47.5508 38.3385 38.3315 47.5628 26.9999 47.5628Z" fill="black"/>
                    <path d="M34.0276 13.614H29.0819C29.0707 13.6139 29.0594 13.6144 29.0482 13.6155C24.055 13.6313 23.4028 17.1199 23.4002 20.169C23.3933 20.1979 23.3898 20.2275 23.3897 20.2571V22.3976H19.9724C19.873 22.3976 19.7777 22.4372 19.7074 22.5075C19.6371 22.5778 19.5977 22.6732 19.5977 22.7726V28.0515C19.5977 28.151 19.6371 28.2464 19.7074 28.3167C19.7777 28.387 19.873 28.4265 19.9724 28.4265H23.3897V40.0106C23.3897 40.1101 23.4292 40.2055 23.4995 40.2758C23.5698 40.3461 23.6651 40.3856 23.7645 40.3856H28.7518C28.8512 40.3856 28.9465 40.3461 29.0168 40.2758C29.0871 40.2055 29.1265 40.1101 29.1265 40.0106V28.4269H34.0272C34.1266 28.4269 34.2219 28.3874 34.2922 28.3171C34.3625 28.2467 34.402 28.1514 34.402 28.0519V22.773C34.402 22.6736 34.3625 22.5782 34.2922 22.5079C34.2219 22.4375 34.1266 22.398 34.0272 22.398H28.7518V19.5604H34.0272C34.1266 19.5604 34.2219 19.5209 34.2922 19.4506C34.3625 19.3802 34.402 19.2849 34.402 19.1854V13.989C34.402 13.9398 34.3924 13.891 34.3736 13.8455C34.3548 13.8 34.3272 13.7587 34.2925 13.7239C34.2577 13.6891 34.2164 13.6614 34.171 13.6426C34.1255 13.6237 34.0768 13.614 34.0276 13.614Z" fill="black"/>
                    </svg>
                </a>
                <a className={(props.contInfo.instagram.isActive ? "" : "hidden ") +"mx-1.5 transform text-gray-400 transition-colors duration-300 hover:text-blue-500 dark:hover:text-blue-400"} href={props.contInfo.instagram.link} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                    <path d="M26.9999 4.18726C14.4285 4.18726 4.20068 14.421 4.20068 27C4.20068 39.579 14.4285 49.8128 26.9999 49.8128C39.5717 49.8128 49.7995 39.579 49.7995 27C49.7995 14.421 39.5713 4.18726 26.9999 4.18726ZM26.9999 47.5628C15.6683 47.5628 6.44939 38.3381 6.44939 27C6.44939 15.6619 15.6683 6.43726 26.9999 6.43726C38.3315 6.43726 47.5508 15.6619 47.5508 27C47.5508 38.3381 38.3315 47.5628 26.9999 47.5628Z" fill="black"/>
                    <path d="M34.1337 16.0128H19.8656C17.7612 16.0128 16.0488 17.7258 16.0488 19.8318V34.1737C16.0488 36.2793 17.7608 37.9927 19.8656 37.9927H34.1333C36.2377 37.9927 37.9501 36.2797 37.9501 34.1737V19.8314C37.9501 17.7258 36.2381 16.0128 34.1337 16.0128ZM30.3 26.9621C30.3 28.7831 28.8196 30.2643 26.9996 30.2643C25.1797 30.2643 23.6993 28.7831 23.6993 26.9621C23.6993 26.0587 24.0643 25.2397 24.6535 24.6431H29.3454C29.9574 25.2595 30.3005 26.0932 30.3 26.9621ZM31.2568 19.0683H34.5999V22.5142H31.2568V19.0683ZM34.1337 35.7423H19.8656C19.4499 35.7418 19.0513 35.5764 18.7574 35.2822C18.4634 34.9881 18.298 34.5893 18.2975 34.1733V24.6431H21.9644C21.6267 25.3695 21.4514 26.1609 21.4506 26.9621C21.4506 30.0239 23.9399 32.5143 26.9996 32.5143C30.0594 32.5143 32.5487 30.0236 32.5487 26.9621C32.5487 26.1337 32.3613 25.3499 32.0349 24.6431H35.7014V34.1733C35.7009 34.5892 35.5356 34.988 35.2417 35.2821C34.9478 35.5762 34.5493 35.7417 34.1337 35.7423Z" fill="black"/>
                    </svg>
                </a>
                <a className={(props.contInfo.youtube.isActive ? "" : "hidden ") + "mx-1.5 transform text-gray-400 transition-colors duration-300 hover:text-blue-500 dark:hover:text-blue-400"} href={props.contInfo.youtube.link}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                    <path d="M26.9999 4.18726C14.4285 4.18726 4.20068 14.421 4.20068 27C4.20068 39.579 14.4285 49.8128 26.9999 49.8128C39.5717 49.8128 49.7999 39.579 49.7999 27C49.7999 14.421 39.5713 4.18726 26.9999 4.18726ZM26.9999 47.5628C15.6683 47.5628 6.44939 38.3381 6.44939 27C6.44939 15.6619 15.6683 6.43726 26.9999 6.43726C38.3315 6.43726 47.5512 15.6619 47.5512 27C47.5512 38.3381 38.3315 47.5628 26.9999 47.5628Z" fill="black"/>
                    <path d="M36.12 18.9067C36.12 18.9067 33.5433 18.5317 26.8441 18.5317C20.1212 18.5317 17.7552 18.9067 17.7552 18.9067C17.3589 18.9067 16.9666 18.9847 16.6005 19.1364C16.2344 19.2881 15.9018 19.5105 15.6216 19.7908C15.3414 20.0711 15.1191 20.4039 14.9675 20.7702C14.8158 21.1365 14.7378 21.529 14.7378 21.9255V31.8866C14.7378 32.283 14.8159 32.6756 14.9676 33.0418C15.1193 33.408 15.3415 33.7408 15.6217 34.0211C15.9019 34.3014 16.2345 34.5237 16.6006 34.6753C16.9666 34.827 17.359 34.905 17.7552 34.905C17.7552 34.905 20.1212 35.28 26.8516 35.28C33.543 35.28 36.1196 34.905 36.1196 34.905C36.9197 34.905 37.6871 34.587 38.2529 34.021C38.8188 33.4549 39.1368 32.6872 39.137 31.8866V21.9255C39.137 21.1249 38.8191 20.357 38.2533 19.7909C37.6875 19.2248 36.9201 18.9067 36.12 18.9067ZM23.2439 31.686V22.1797L31.4719 26.9325L23.2439 31.686Z" fill="black"/>
                    </svg>
                </a>
                <a className={(props.contInfo.linkedin.isActive ? "" : "hidden ") + "mx-1.5 transform text-gray-400 transition-colors duration-300 hover:text-blue-500 dark:hover:text-blue-400"} href={props.contInfo.linkedin.link}>
                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                    <path d="M26.9998 4.18726C14.4284 4.18726 4.20056 14.421 4.20056 27C4.20056 39.5794 14.4284 49.8128 26.9998 49.8128C39.5715 49.8128 49.7998 39.5794 49.7998 27C49.7994 14.421 39.5712 4.18726 26.9998 4.18726ZM26.9998 47.5628C15.6682 47.5628 6.44927 38.3385 6.44927 27C6.44927 15.6619 15.6682 6.43726 26.9998 6.43726C38.3314 6.43726 47.5511 15.6619 47.5511 27C47.5507 38.3385 38.3314 47.5628 26.9998 47.5628Z" fill="black"/>
                    <path d="M15.4197 22.1663H20.6959V35.9415H15.4197V22.1663Z" fill="black"/>
                    <path d="M18.0054 19.9688C19.4802 19.9688 20.6758 18.8774 20.6758 17.5312C20.6758 16.1851 19.4802 15.0938 18.0054 15.0938C16.5306 15.0938 15.3351 16.1851 15.3351 17.5312C15.3351 18.8774 16.5306 19.9688 18.0054 19.9688Z" fill="black"/>
                    <path d="M33.9584 21.855C32.8779 21.855 31.9623 22.0519 31.2098 22.4452C30.458 22.839 29.7151 23.4937 28.9802 24.4102V22.1662H24.0668V35.9415H29.343V29.3261C29.343 28.0721 29.542 27.2036 29.9396 26.7191C30.3373 26.235 30.8552 25.9927 31.495 25.9927C32.074 25.9927 32.5234 26.1724 32.8435 26.5312C33.1635 26.8901 33.3228 27.5017 33.3228 28.3669V35.9415H38.6253V27.1864C38.6253 25.3702 38.2122 24.0277 37.3866 23.1585C36.5621 22.2892 35.4186 21.855 33.9584 21.855Z" fill="black"/>
                    </svg>
                </a>
                <a className={(props.contInfo.twitter.isActive ? "" : "hidden ") + "mx-1.5 transform text-gray-400 transition-colors duration-300 hover:text-blue-500 dark:hover:text-blue-400"} href={props.contInfo.twitter.link}>
                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                    <path d="M26.9998 4.18726C14.4284 4.18726 4.20056 14.421 4.20056 27C4.20056 39.5794 14.4284 49.8128 26.9998 49.8128C39.5715 49.8128 49.7998 39.5794 49.7998 27C49.7994 14.421 39.5712 4.18726 26.9998 4.18726ZM26.9998 47.5628C15.6682 47.5628 6.44927 38.3385 6.44927 27C6.44927 15.6619 15.6682 6.43726 26.9998 6.43726C38.3314 6.43726 47.5511 15.6619 47.5511 27C47.5507 38.3385 38.3314 47.5628 26.9998 47.5628Z" fill="black"/>
                    <path d="M37.1815 19.5674C37.7055 19.2967 38.9603 18.4923 39.5517 16.7658C39.5517 16.7658 38.1264 17.7971 36.1865 17.9186L36.1513 17.9381C35.6429 17.3815 35.0241 16.9371 34.3343 16.6335C33.6446 16.3298 32.8991 16.1735 32.1456 16.1744C29.1443 16.1744 26.7116 18.6089 26.7116 21.6119C26.7116 22.0676 26.7682 22.5101 26.8742 22.9331L26.848 22.8937C26.848 22.8937 21.0276 23.2274 15.5404 17.2211C15.5404 17.2211 13.1144 21.3464 17.2681 24.5013C16.3585 24.6528 14.7522 23.8338 14.7522 23.8338C14.7522 23.8338 14.8429 28.1711 19.1477 29.2331C18.4506 29.4153 17.1467 29.4753 16.7225 29.2938C16.7225 29.2938 17.3588 32.5998 21.7551 33.1766C20.6333 33.8137 18.5417 35.7547 13.8119 35.3906C13.8119 35.3906 17.2082 38.1809 23.5139 37.7872C29.8197 37.3934 34.3976 33.1458 36.2468 28.6567C38.096 24.1676 37.5814 21.6116 37.5814 21.6116C37.5814 21.6116 39.1267 20.8001 40.1888 18.8591C39.6982 19.0061 37.8786 19.4508 37.2014 19.6154C37.1946 19.5995 37.1879 19.5835 37.1815 19.5674Z" fill="black"/>
                    </svg>
                </a>
                <a className={(props.contInfo.tiktok.isActive ? "" : "hidden ") + "mx-1.5 transform text-gray-400 transition-colors duration-300 hover:text-blue-500 dark:hover:text-blue-400"} href={props.contInfo.tiktok.link}>
                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
  <path d="M26.9998 4.18726C14.4284 4.18726 4.20056 14.421 4.20056 27C4.20056 39.5794 14.4284 49.8128 26.9998 49.8128C39.5715 49.8128 49.7998 39.5794 49.7998 27C49.7994 14.421 39.5712 4.18726 26.9998 4.18726ZM26.9998 47.5628C15.6682 47.5628 6.44927 38.3385 6.44927 27C6.44927 15.6619 15.6682 6.43726 26.9998 6.43726C38.3314 6.43726 47.5511 15.6619 47.5511 27C47.5507 38.3385 38.3314 47.5628 26.9998 47.5628Z" fill="black"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M32.3738 15.021C32.3755 15.4023 32.5005 20.9136 38.9978 21.2573C38.9978 22.8467 39 23.999 39 25.5156C38.508 25.541 34.7226 25.2964 32.3667 23.4258L32.3595 31.7158C32.449 37.4696 27.6921 40.9711 21.4746 39.7616C10.7531 36.9067 14.3158 22.7514 25.0812 24.2666C25.0812 28.8364 25.0839 24.2651 25.0839 28.8364C20.6365 28.2539 19.1488 31.5473 20.3309 33.9052C21.406 36.0522 25.8343 36.5175 27.3789 33.4887C27.5539 32.8959 27.6411 32.2202 27.6411 31.4614V15L32.3738 15.021Z" fill="black"/>
</svg>
                </a>




            </div>
        </div>
        <div className=" space-y-6 text-xs">
        <div className={(props.contInfo.phone.isActive ? "" : "hidden ") + "flex items-center "}>
            <svg xmlns="http://www.w3.org/2000/svg" className="aspect-w-3 aspect-h-2" width="45" height="45" viewBox="0 0 69 69" fill="none">
            <g clip-path="url(#clip0_68_312)">
                <path d="M34.5 69C53.5538 69 69 53.5538 69 34.5C69 15.4462 53.5538 0 34.5 0C15.4462 0 0 15.4462 0 34.5C0 53.5538 15.4462 69 34.5 69Z" fill="url(#paint0_linear_68_312)"/>
                <path d="M43.1414 38.4274L38.4274 43.1414L25.8568 30.5707L30.5707 25.8568L22.7139 18L18 22.7139C17.9998 26.4284 18.7312 30.1065 20.1525 33.5384C21.5738 36.9702 23.6571 40.0884 26.2836 42.7151C28.91 45.3417 32.0282 47.4253 35.4599 48.8468C38.8917 50.2684 42.5698 51 46.2843 51L51 46.2843L43.1414 38.4274Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_68_312" x1="0" y1="34.5" x2="69" y2="34.5" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EE0A79"/>
                <stop offset="1" stop-color="#FF6900"/>
                </linearGradient>
                <clipPath id="clip0_68_312">
                <rect width="69" height="69" fill="white"/>
                </clipPath>
            </defs>
            </svg>
            <div className="mx-2">
                <p className="font-bold text-base">Telefóno</p>
                <a href={props.contInfo.phone.link} className="font-bold text-base">{props.contInfo.phone.text}</a>
            </div>
        </div>
        <div className={(props.contInfo.mail.isActive ? "" : "hidden ") + "flex items-center"}>
            <svg xmlns="http://www.w3.org/2000/svg" className="aspect-w-3 aspect-h-2" width="45" height="45" viewBox="0 0 69 69" fill="none">
            <g clip-path="url(#clip0_68_312)">
                <path d="M34.5 69C53.5538 69 69 53.5538 69 34.5C69 15.4462 53.5538 0 34.5 0C15.4462 0 0 15.4462 0 34.5C0 53.5538 15.4462 69 34.5 69Z" fill="url(#paint0_linear_68_312)"/>
                <path d="M50.1555 19.0391L34.9184 19.0781L19.6812 19H19.6738C17.6254 19 16 21.0234 16 23.5547V44.7578C16 47.2891 17.6254 49.3125 19.6738 49.3125H50.1777C52.2262 49.3125 53.8516 47.2891 53.8516 44.7578V23.5547C53.8516 21.0234 52.241 19.0391 50.1555 19.0391ZM48.9605 22.1641L35.3488 35.2656L35.2969 35.3125L35.2523 35.3672C35.0891 35.5547 34.8961 35.5781 34.7922 35.5781C34.6883 35.5781 34.4953 35.5469 34.3395 35.3516L34.2949 35.2969L21.2844 22.1016L34.9184 22.1953L48.9605 22.1641ZM50.1777 46.1875H19.6738C19.4141 46.1875 18.9688 45.6719 18.9688 44.7578V24.0547L32.15 37.4609C32.818 38.2422 33.7605 38.6953 34.7625 38.7109H34.807C35.7941 38.7109 36.7145 38.2813 37.3898 37.5312L50.8977 24.5156V44.7578C50.8828 45.6719 50.4375 46.1875 50.1777 46.1875Z" fill="white"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_68_312" x1="0" y1="34.5" x2="69" y2="34.5" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EE0A79"/>
                <stop offset="1" stop-color="#FF6900"/>
                </linearGradient>
                <clipPath id="clip0_68_312">
                <rect width="69" height="69" fill="white"/>
                </clipPath>
            </defs>
            </svg>
            <div className="mx-2">
                <p className="font-bold text-base">Email</p>
                <a href={props.contInfo.mail.link} className="font-bold text-base ">{props.contInfo.mail.text}</a>
            </div>
        </div>
        <div className={(props.contInfo.officeHours.isActive ? "" : "hidden ") +"flex items-center"}>
            <svg xmlns="http://www.w3.org/2000/svg"  className="aspect-w-3 aspect-h-2 w-4rem"  width="60" height="60" viewBox="0 0 69 69" fill="none">
            <g clip-path="url(#clip0_68_320)">
                <path d="M34.5 69C53.5538 69 69 53.5538 69 34.5C69 15.4462 53.5538 0 34.5 0C15.4462 0 0 15.4462 0 34.5C0 53.5538 15.4462 69 34.5 69Z" fill="url(#paint0_linear_68_320)"/>
                <path d="M34.5 51C24.2984 51 16 43.3745 16 34C16 24.6255 24.2984 17 34.5 17C44.7016 17 53 24.6255 53 34C53 43.3745 44.7002 51 34.5 51ZM34.5 19.72C25.9308 19.72 18.96 26.1256 18.96 34C18.96 41.8744 25.9308 48.28 34.5 48.28C43.0692 48.28 50.04 41.8744 50.04 34C50.04 26.1256 43.0677 19.72 34.5 19.72ZM34.3816 35.2512H24.88C24.4875 35.2512 24.111 35.1079 23.8335 34.8529C23.5559 34.5978 23.4 34.2519 23.4 33.8912C23.4 33.5305 23.5559 33.1846 23.8335 32.9295C24.111 32.6745 24.4875 32.5312 24.88 32.5312H32.9016V22.44C32.9016 22.0793 33.0575 21.7334 33.3351 21.4783C33.6126 21.2233 33.9891 21.08 34.3816 21.08C34.7741 21.08 35.1506 21.2233 35.4281 21.4783C35.7057 21.7334 35.8616 22.0793 35.8616 22.44V33.8912C35.8616 34.2519 35.7057 34.5978 35.4281 34.8529C35.1506 35.1079 34.7741 35.2512 34.3816 35.2512Z" fill="white"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_68_320" x1="0" y1="34.5" x2="69" y2="34.5" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EE0A79"/>
                <stop offset="1" stop-color="#FF6900"/>
                </linearGradient>
                <clipPath id="clip0_68_320">
                <rect width="69" height="69" fill="white"/>
                </clipPath>
            </defs>
            </svg>
            <div className="mx-2 lg:w-[36rem] md:w-[48rem] sm:w-[26rem]">
                <p className="font-bold text-base">Horario de atención al cliente</p>
                <span className="font-bold text-base">{props.contInfo.officeHours.text}</span>
            </div>
        </div>
        <div className={(props.contInfo.location.isActive ? "" : "hidden ") +"flex items-center"}>
            <svg xmlns="http://www.w3.org/2000/svg"  className="aspect-w-3 aspect-h-2"  width="45" height="45" viewBox="0 0 69 69" fill="none">
            <g clip-path="url(#clip0_68_336)">
                <path d="M34.5 69C53.5538 69 69 53.5538 69 34.5C69 15.4462 53.5538 0 34.5 0C15.4462 0 0 15.4462 0 34.5C0 53.5538 15.4462 69 34.5 69Z" fill="url(#paint0_linear_68_336)"/>
                <path d="M35 16C28.3813 16 23 21.0551 23 27.2727C23 30.5031 25.1247 35.2369 27.5975 39.3219C28.835 41.3643 30.164 43.2222 31.4037 44.5989C31.7338 44.966 32.0577 45.2964 32.375 45.5909H23.75C23.5511 45.5909 23.3603 45.6651 23.2197 45.7973C23.079 45.9294 23 46.1086 23 46.2955C23 46.4823 23.079 46.6615 23.2197 46.7936C23.3603 46.9258 23.5511 47 23.75 47H46.25C46.4489 47 46.6397 46.9258 46.7803 46.7936C46.921 46.6615 47 46.4823 47 46.2955C47 46.1086 46.921 45.9294 46.7803 45.7973C46.6397 45.6651 46.4489 45.5909 46.25 45.5909H37.625C37.9423 45.2971 38.2655 44.966 38.5962 44.5989C39.836 43.2222 41.165 41.3643 42.4017 39.3219C44.8752 35.2369 47 30.5031 47 27.2727C47 21.0551 41.6187 16 35 16ZM35 17.4091C40.808 17.4091 45.5 21.8167 45.5 27.2727C45.5 29.8795 43.4998 34.6564 41.0975 38.6237C39.8975 40.6077 38.6015 42.4113 37.451 43.6893C36.8758 44.3277 36.3342 44.8349 35.8865 45.1597C35.4387 45.4845 35.0825 45.5909 35 45.5909C34.9175 45.5909 34.562 45.4852 34.1135 45.1597C33.665 44.8342 33.125 44.3284 32.5497 43.6893C31.3985 42.4113 30.1032 40.6077 28.9017 38.6237C26.5002 34.6564 24.5 29.8788 24.5 27.2727C24.5 21.8167 29.192 17.4091 35 17.4091Z" fill="white"/>
                <path d="M35 24C32.8 24 31 25.575 31 27.5C31 29.425 32.8 31 35 31C37.2 31 39 29.425 39 27.5C39 25.575 37.2 24 35 24ZM35 25.4C36.3352 25.4 37.4 26.3317 37.4 27.5C37.4 28.6683 36.3352 29.6 35 29.6C33.6648 29.6 32.6 28.6683 32.6 27.5C32.6 26.3317 33.6648 25.4 35 25.4Z" fill="white"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_68_336" x1="0" y1="34.5" x2="69" y2="34.5" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EE0A79"/>
                <stop offset="1" stop-color="#FF6900"/>
                </linearGradient>
                <clipPath id="clip0_68_336">
                <rect width="69" height="69" fill="white"/>
                </clipPath>
            </defs>
            </svg>
            <div className="mx-2 w-[14rem]">
                <a href={props.contInfo.location.link} className="font-bold text-base">{props.contInfo.location.text}</a>
            </div>
        </div>

    </div> 
    </div>
    );

}
export {ContactCard}