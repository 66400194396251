import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";
import { ErrorScreen } from "./error-screen";
function RecetaGeneral(){
    const [error, setError] = useState(false);
    const [info , setInfo] = useState({title: "", text: "",img: "./img/cargando.png"});
    const [ingredient , setIngredient] = useState({title: "", text: "",img: "./img/cargando.png"});
    const [instrucion , setInstruction] = useState({title: "", text: "",img: "./img/cargando.png"});
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/recipesFull')
            .then((res) => {
                const recipeId = window.location.pathname.split("/")[2];
                const recipe = res.data.recipesFull.find((item) => item.recipe.id === parseInt(recipeId));
    
                if (recipe) {
                    const recipeData = recipe.recipe;
                    // También puedes establecer ingredientes e instrucciones si es necesario
                    setInfo({ title: recipeData.info.title, text: recipeData.info.text, img: recipeData.info.img });
                    setIngredient({ title: recipeData.ingredients.title, text: recipeData.ingredients.text, img: recipeData.ingredients.img });
                    setInstruction({ title: recipeData.instructions.title, text: recipeData.instructions.text, img: recipeData.instructions.img });
    
                    setIsLoading(false);
     
                    }

            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                if (isLoading) {
                    setIsLoading(false);
                }
            });
    }, []);
    return(
        <>
     {isLoading ? <div className="loader-cont"><div className="lds-dual-ring"></div></div> :
                error ? <ErrorScreen /> :
        <div>
                {/* Parte de INFORMACION */}  
                <div className="max-w-7xl px-6 py-2 mx-auto">
                        <div class=" w-full mt-8 md:flex md:items-center xl:mt-12">
                            <div class="absolute w-1/2 bg-gray-200 z-0 md:h-96 "></div>
                            <div class="w-full p-6 bg-gray-200 md:flex md:items-center rounded-2xl md:bg-transparent md:p-0 lg:px-12 md:justify-evenly">
                            <div class="mt-2 md:w-full md:mx-6 text-black">
                                <p class="text-xl font-medium tracking-tight relative z-1">{info.title}</p>
                                <p class="mt-4 text-lg leading-relaxed md:text-xl relative z-1">{info.text}</p>
                            </div>
                            <img class="h-60 w-full md:mx-6 rounded-2xl object-cover shadow-md md:h-[32rem] md:w-[70rem] lg:h-50rem lg:w-[80rem]" src={info.img} alt="imagen de receta" />

                            </div>
                        </div>
                    </div>
                {/* Parte de Ingrediente */}  
                <div class="relative flex mx-auto">
                    <div class="min-h-[80vh] lg:w-1/3"></div>
                    <div class="hidden w-3/4 min-h-[80vh] bg-gray-100 dark:bg-gray-800 lg:block"></div>

                    <div class="flex flex-col justify-center w-full min-h-[80vh] px-6 mx-auto lg:absolute lg:inset-x-0 ">
                          <div class="mt-5 lg:flex lg:items-center">
                            <img class="object-cover object-center w-full lg:w-[32rem] rounded-lg h-96 lg:h-[32rem] hidden md:block sm:hidden" src={ingredient.img} alt="imagen de receta"/>
            
                            <div class="mt-8 lg:px-10 lg:mt-0 ">
                                <h1 class="text-2xl font-semibold text-center text-gray-800 dark:text-white lg:w-72">
                                    {ingredient.title}
                                </h1>
                                <p class="lg:max-w-lg mt-6 text-gray-500 text-center dark:text-gray-400">
                                    {parse(ingredient.text)}
                                </p>

                            </div>
                        </div>
                    </div>

                </div>
                {/* Iamgen*/}  
                <div class=" container px-6 mx-auto">
                    <div class="lg:flex lg:items-center lg:-mt-24">

                        <div class="mt-8 lg:w-1/2 lg:px-6 lg:mt-0">
                                        
                        </div>
                        <div class=" w-full mt-8 md:flex md:items-center xl:mt-12 ">
                                        <div class="absolute w-2/5 bg-imagen z-0 md:h-96 ">
                                            
                                        </div>
                                        <div class="w-full p-6 bg-gray-200 md:flex md:items-center md:bg-transparent md:p-0">
                                            <svg
                                            className="h-60 w-full lg:ml-32 rounded-2xl object-cover md:h-[21rem] md:w-[70rem] lg:h-27rem lg:w-[24rem] lg:mt-[8rem] "
                                            fill="none"
                                            viewBox="0 0 93 88"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path
                                                d="M92.665 74.83h-74.32V.51h74.32v74.32zm-69.303-4.861h64.442V5.527H23.362v64.442z"
                                                fill="#9D9D9D"
                                            />
                                            <path
                                                d="M74.32 88H0V13.68h74.32V88zM5.017 83.14H69.46V18.697H5.017v64.441z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M74.32 88H0V13.68h74.32V88zM5.017 83.14H69.46V18.697H5.017v64.441z"
                                                fill="currentColor"
                                                fillOpacity=".2"
                                            />
                                            </svg>
                                        </div>
                                    </div>
                    </div>
                </div>
                {/* Parte de recete */}  
                <div className="container mx-auto px-6 py-16 text-center">
                    <p className="mt-6 text-base text-justify	">
                        {parse(instrucion.text)}
                    </p>
                </div>
                <div className="flex space-x-2 justify-center ">
                <NavLink to="/blog"><button type="button" className="my-10 inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 transition duration-150">Regresar a Blog</button></NavLink>
                </div>

        </div>
}
</>
    );

}export {RecetaGeneral};