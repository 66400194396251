import React from "react";

function ProductCard(props){
    return(
     
        <div className="overflow-hidden mx-auto drop-shadow-lg bg-white rounded w-4/5 cursor-pointer transition ease-in-out delay-150  hover:-translate-y-1  hover:bg-gray-200  duration-300 ">
            <div className="flex justify-center items-center bg-[#D7D0B7] "><img src={props.productImg} className="h-60 w-auto" alt="Imagen del producto" /></div>
            <div className="text-center">
                <p  className="inline-block mb-3 p-2 text-black font-bold text-xl ">{props.title}</p>
            </div>
        </div>

    );
}
export {ProductCard}