import React from "react";
function RecipesCard(props) {
  return (
    <div className="relative flex w-96 flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md  hover:bg-gray-200 ">
      <div className="relative mx-4 mt-4 h-44 overflow-hidden rounded-xl bg-clip-border shadow-lg">
        <img className="w-full"
          src={props.img}
          alt="Iamgen de receta"
        />
      </div>
      <div className="p-6 text-center">
        <h4 className="mb-2 block font-sans text-2xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
        {props.title}
        </h4>
        <p className="mb-4 p-2 text-sm text-gray-700 text-justify">
          {props.text}
        </p>
      </div>
    </div>
  );
}
export { RecipesCard };
